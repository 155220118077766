import {Box, Button, Card, CircularProgress, keyframes, Modal, styled, Typography} from "@mui/material";
import {CheckCircle} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useHttp} from "../../hooks/use-http";

// Define the MostPopularLabel styled component
const MostPopularLabel = styled("div")(({theme}) => ({
  position: "absolute",
  top: "10px",
  right: "10px",
  backgroundColor: theme.palette.warning.main,
  color: "#FAFAFA",
  padding: "5px 10px",
  borderRadius: "5px",
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}));

const SubscriptionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  padding: "20px",
  gap: "20px",
  flexWrap: "wrap", // Allow items to wrap to a new row
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
  maxHeight: "80vh", // Adjusted max height to 80% of viewport height
  overflowY: "auto", // Add scroll if content overflows
}));

const PlanContainer = styled(Card)(({ theme, variant, isCurrent }) => ({
  width: isCurrent ? "500px" : "300px", // Adjust width for current subscription
  padding: "20px",
  textAlign: "left",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  border: `2px solid ${theme.palette.primary.main}`,
  gap: "10px",
  boxSizing: "border-box",
  height: "100%",
  alignSelf: "stretch",
  position: "relative",
  maxHeight: "400px", // Adjusted max height to 300px
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

// Define keyframes for the gradient animation
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Update the PlanButton styled component
const PlanButton = styled(Button)(({theme, variant}) => ({
  marginTop: "auto", // Push the button to the bottom
  borderRadius: "20px",
  fontWeight: "bold",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.03)",
  },
  ...(variant === "sustainer" && {
    background: "linear-gradient(45deg, #09A4C6, #6A00AB)",
    backgroundSize: "200% 200%",
    color: "#FAFAFA",
    border: `2px solid #FAFAFA`,
    animation: `${gradientAnimation} 4s ease infinite`,
  }),
}));

const CancelButton = styled(Button)(({theme}) => ({
  marginTop: "20px",
  borderRadius: "20px",
  fontWeight: "bold",
  backgroundColor: theme.palette.error.main,
  color: "#FAFAFA",
  "&:hover": {
    backgroundColor: theme.palette.error.dark,
  },
}));

const SubscriptionTitle = styled(Typography)(() => ({
  fontSize: "1.5rem",
  display: "block",
  textAlign: "left",
  marginBottom: "0.5rem"
}));

const SubTitle = styled(Typography)(({theme}) => ({
  fontSize: "0.875rem",
  color: theme.palette.text.secondary,
  display: "block",
  textAlign: "left",
  marginBottom: "1rem"
}));

const FeatureItem = styled(Typography)(({theme}) => ({
  display: "flex",
  alignItems: "center", // Vertically center the features and the CheckCircle icon
  gap: "5px",
}));

// Update the styled component for the image
const FeatureImage = styled("img")(({theme}) => ({
  borderRadius: "8px",
  cursor: "pointer",
  width: "30px", // Adjust the size as needed
  height: "30px", // Adjust the size as needed
}));

// Create a modal component for the full-size image
const ImageModal = ({src, alt, open, onClose}) => (
  <Modal open={open} onClose={onClose}>
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      outline: 0,
    }}>
      <img src={src} alt={alt} style={{width: '100%', height: 'auto', borderRadius: '8px'}} />
    </Box>
  </Modal>
);

const SubscriptionPlan = ({subscription, handleSelectPlan}) => {
  const [imageOpen, setImageOpen] = useState(false);

  const handleImageClick = () => {
    setImageOpen(true);
  };

  const handleClose = () => {
    setImageOpen(false);
  };

  const priceInteger = Math.floor(subscription.price / 100);
  const priceDecimal = subscription.price % 100;

  return (
    <PlanContainer variant={subscription.name.toLowerCase()} style={{position: "relative"}}>
      <Typography variant="h4">
        ${priceInteger}
        {priceDecimal > 0 && (
          <Typography variant="caption" sx={{verticalAlign: 'top', fontSize: '0.75rem'}}>
            {priceDecimal}
          </Typography>
        )}
        <Typography variant="caption">/ month</Typography>
      </Typography>
      <Typography variant="h5" sx={{fontWeight: 'bold'}}>{subscription.name}</Typography>
      {subscription.features.map((feature, idx) => (
        <FeatureItem key={idx} variant="body2">
          <CheckCircle fontSize="small"/> {feature}
          {feature.includes("lapel pin") && (
            <>
              <FeatureImage src="/assets/images/products/oss-lapel-pin.png" alt="OSS Supporter lapel pin" onClick={handleImageClick} />
              <ImageModal src="/assets/images/products/oss-lapel-pin.png" alt="OSS Supporter lapel pin" open={imageOpen} onClose={handleClose} />
            </>
          )}
        </FeatureItem>
      ))}
      <PlanButton
        variant={subscription.name.toLowerCase() === "sustainer" ? "sustainer" : "contained"}
        color="primary"
        onClick={() => handleSelectPlan(subscription.id)}
      >
        Select Plan
      </PlanButton>
    </PlanContainer>
  );
};

const CurrentSubscription = ({currentSubscription, handleCancelSubscription}) => {
  const [imageOpen, setImageOpen] = useState(false);

  const handleImageClick = () => {
    setImageOpen(true);
  };

  const handleClose = () => {
    setImageOpen(false);
  };

  const priceInteger = Math.floor(currentSubscription.additionalInfo.price / 100);
  const priceDecimal = currentSubscription.additionalInfo.price % 100;

  return (
    <PlanContainer isCurrent>
      <Typography variant="h4">
        ${priceInteger}
        {priceDecimal > 0 && (
          <Typography variant="caption" sx={{verticalAlign: 'top', fontSize: '0.75rem'}}>
            {priceDecimal}
          </Typography>
        )}
        <Typography variant="caption">/ month</Typography>
      </Typography>
      <Typography variant="h5" sx={{fontWeight: 'bold'}}>{currentSubscription.additionalInfo.name}</Typography>
      {currentSubscription.additionalInfo.features.map((feature, idx) => (
        <FeatureItem key={idx} variant="body2">
          <CheckCircle fontSize="small"/> {feature}
          {feature.includes("lapel pin") && (
            <>
              <FeatureImage src="/assets/images/products/oss-lapel-pin.png" alt="OSS Supporter lapel pin" onClick={handleImageClick} />
              <ImageModal src="/assets/images/products/oss-lapel-pin.png" alt="OSS Supporter lapel pin" open={imageOpen} onClose={handleClose} />
            </>
          )}
        </FeatureItem>
      ))}
      <CancelButton onClick={handleCancelSubscription}>
        Cancel Subscription
      </CancelButton>
    </PlanContainer>
  );
};

const SubscriptionMessage = () => (
  <Typography variant="body1" sx={{mt: 3}}>
    As a <b>software developer</b>, donating to open source projects and their creators is more than just a charitable
    act—it's an investment in the ecosystem that powers much of our work. By contributing financially:
    <ul>
      <li>You enable dedicated developers to focus on <b>creating</b> and <b>maintaining</b> essential tools.</li>
      <li>You help ensure the sustainability and improvement of software you likely use daily.</li>
      <li>You foster <b>innovation</b> and <b>collaboration</b> in the tech community.</li>
      <li>You pay it forward, supporting those whose work has likely benefited you.</li>
      <li>You contribute to a culture of mutual support and appreciation in software development.</li>
    </ul>
    Your donations, no matter how small, can make a significant impact. They allow passionate developers to continue
    their valuable work, ultimately benefiting the entire software development community.
    <br/><br/>
    Thank you!
  </Typography>
);

export default function Subscription() {
  const [subscriptions, setSubscriptions] = useState([
    {
      id: "price_1Pv8lSJwMgFSZnqLNp020rcf",
      features: ["Unique digital sticker collection"],
      name: "Contributor",
      price: 500
    },
    {
      id: "price_1Pv8nFJwMgFSZnqLe1jChB26",
      features: ["Physical limited-edition \"OSS Supporter\" lapel pin", "Unique digital sticker collection",],
      name: "Supporter",
      price: 1000
    },
    {
      id: "price_1Pv8nyJwMgFSZnqLzgPlEfIl",
      features: ["Physical limited-edition \"OSS Supporter\" lapel pin", "Unique digital sticker collection", "Exclusive Discord community access"],
      name: "Sustainer",
      price: 2000
    },
  ]);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const {request, loading} = useHttp();

  const getCurrentSubscription = async () => {
    let res = await request('/subscription', 'GET');
    return res;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentSub = await getCurrentSubscription();
        const currentPriceId = currentSub ? currentSub.items.data[0].price.id : null;

        const currentSubWithInfo = {
          ...currentSub,
          additionalInfo: subscriptions.find(sub => sub.id === currentPriceId)
        };
        setCurrentSubscription(currentSubWithInfo);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, []);

  const handleSelectPlan = async (price_id) => {
    try {
      let res = await request(`/stripe/session?price_id=${price_id}`, 'GET');
      window.location.href = res["session_url"];
    } catch (e) {
      console.error(e);
    }
  }

  const handleCancelSubscription = async () => {
    try {
      let res = await request('/subscription', 'DELETE');
      setCurrentSubscription(null);
    } catch (e) {
      console.error(e);
    }
  }

  if (loading) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <CircularProgress/>
      </Box>
    );
  }

  return (
    <Box sx={{px: 3, py: 2, mb: 3}}>
      <SubscriptionTitle>Subscription Status</SubscriptionTitle>
      <SubTitle>
        {currentSubscription && currentSubscription.status === "active" ? "Your current subscription" : "Choose how much you want to support open source projects"}
      </SubTitle>
      <Box sx={{display: 'flex', flexDirection: 'row', gap: '20px'}}>
        <SubscriptionContainer>
          {currentSubscription && currentSubscription.status === "active" ? (
            <CurrentSubscription currentSubscription={currentSubscription}
                                 handleCancelSubscription={handleCancelSubscription}/>
          ) : (
            subscriptions.map((subscription, index) => (
              <SubscriptionPlan key={index} subscription={subscription} handleSelectPlan={handleSelectPlan}/>
            ))
          )}
        </SubscriptionContainer>
        {currentSubscription && currentSubscription.status === "active" && <SubscriptionMessage/>}
      </Box>
    </Box>
  );
}