import {Box, Card, styled, Typography} from "@mui/material";

const FAQContainer = styled(Box)(({theme}) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "20px",
  padding: "20px",
}));

const QuestionCard = styled(Card)(({theme}) => ({
  padding: "20px",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.02)",
  },
}));

const QuestionHeader = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const questionsAndAnswers = [
  {
    question: "Do you store any packages?",
    answer: "No, we do not store any packages. All packages are installed from the original destination by your package manager. We only track the number of installations and redirect the request to the original package.",
  },
  {
    question: "What is this platform about?",
    answer: "This platform is designed to help developers manage their projects and subscriptions efficiently.",
  },
  {
    question: "How do I subscribe to a plan?",
    answer: "You can subscribe to a plan by navigating to the Subscription section and selecting a plan that suits your needs.",
  },
  {
    question: "How can I view my repositories?",
    answer: "You can view your repositories in the Repositories section after authenticating with your GitHub account.",
  },
  {
    question: "How do I check package usage metrics?",
    answer: "Package usage metrics can be viewed in the Package Usage Metrics section, where data is updated monthly.",
  },
  {
    question: "How do I cancel my subscription?",
    answer: "You can cancel your subscription by navigating to the Subscription section and selecting the Cancel Subscription button.",
  }
];

export default function FAQ() {
  return (
    <Card sx={{margin: "30px", padding: "20px"}}>
      <FAQContainer>
        <Typography variant="h4" sx={{gridColumn: "span 2", mb: 2}}>
          Frequently Asked Questions
        </Typography>
        {questionsAndAnswers.map((item, index) => (
          <QuestionCard key={index}>
            <QuestionHeader>
              <Typography variant="h6">{item.question}</Typography>
            </QuestionHeader>
            <Typography variant="body2" sx={{mt: 2}}>
              {item.answer}
            </Typography>
          </QuestionCard>
        ))}
      </FAQContainer>
      <Box sx={{mt: 4}}>
        <Typography variant="body2">
          If you have more questions please reach out to <a href="mailto:hello@gitgains.org">hello@gitgains.org</a>
        </Typography>
        <a href="https://x.com/OleksandrKhomin" target="_blank" rel="noopener noreferrer"
           style={{textDecoration: 'none'}}>
          <Typography variant="body2" sx={{display: "flex", alignItems: "center", mt: 1}}>
            Or write directly to the Founder on
            <img src="/assets/images/logos/x-handle.svg" alt="X logo" style={{height: 24, margin: '0 8px'}}/>
            or
            <img src="/assets/images/logos/discord-mark-black.svg" alt="Discord logo"
                 style={{height: 24, margin: '0 8px'}}/>
          </Typography>
        </a>
      </Box>
    </Card>
  );
}