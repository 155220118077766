import {connectAuthEmulator} from "firebase/auth";
import {connectFunctionsEmulator} from "firebase/functions";
import auth from "./firebase";

export const PROGRAMMING_LANGUAGES_ICONS = {
  'git': '/assets/images/logos/github-mark.svg',
  'c': '/assets/images/programming_languages/c.svg',
  'c++': '/assets/images/programming_languages/cpp.svg',
  'c#': '/assets/images/programming_languages/csharp.svg',
  'css': '/assets/images/programming_languages/css.svg',
  'dart': '/assets/images/programming_languages/dart.svg',
  'go': '/assets/images/programming_languages/go.svg',
  'haskell': '/assets/images/programming_languages/haskell.svg',
  'html': '/assets/images/programming_languages/html.svg',
  'java': '/assets/images/programming_languages/java.svg',
  'javascript': '/assets/images/programming_languages/javascript.svg',
  'kotlin': '/assets/images/programming_languages/kotlin.svg',
  'lua': '/assets/images/programming_languages/lua.svg',
  'php': '/assets/images/programming_languages/php.svg',
  'python': '/assets/images/programming_languages/python.svg',
  'r': '/assets/images/programming_languages/r.svg',
  'ruby': '/assets/images/programming_languages/ruby.svg',
  'rust': '/assets/images/programming_languages/rust.svg',
  'swift': '/assets/images/programming_languages/swift.svg',
  'typescript': '/assets/images/programming_languages/typescript.svg',
};

export const PACKAGE_MANAGERS_LOGOS = {
  'npm': '/assets/images/logos/npm.svg',
  'yarn': '/assets/images/logos/yarn.svg',
  'pip': '/assets/images/logos/pip.svg',
  'go': '/assets/images/logos/go.svg',
};

export const PACKAGE_MANAGERS_LINKS = {
  'npm': 'https://www.npmjs.com/',
  'yarn': 'https://yarnpkg.com/',
  'pip': 'https://pypi.org/',
  'go': 'https://pkg.go.dev/',
};

export const PROGRAMMING_LANGUAGES = [
  'git',
  'c',
  'c++',
  'c#',
  'css',
  'dart',
  'go',
  'haskell',
  'html',
  'java',
  'javascript',
  'kotlin',
  'lua',
  'php',
  'python',
  'r',
  'ruby',
  'rust',
  'swift',
  'typescript',
];

export const WITHDRAWAL_THRESHOLD = 100;

const API_URL = process.env.REACT_APP_LOCAL === "true" ? 'http://localhost:8080' : 'https://a.gitgains.org';

export { API_URL };